import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const EventImage = () => {
  const data = useStaticQuery(graphql`
    query {
      eventPhoto: file(relativePath: { eq: "event-photo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.eventPhoto.childImageSharp.fluid}
      alt="Adam Smyer in a high-necked sweater, in front of a microphone, reading from his book 'Knucklehead'"
    />
  )
}

export default EventImage
