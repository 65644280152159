import React from "react"

import Layout from "../components/layout"
import EventImage from "../components/event-image"
import SEO from "../components/seo"

const EventsPage = () => (
  <Layout>
    <SEO title="Events" />
    <h1>Events and Interviews</h1>
    <div
      style={{
        maxWidth: `300px`,
        paddingBottom: `0.5rem`,
        marginBottom: `1.45rem`,
      }}
    >
      <EventImage />
      <small>Photo by Ed Newman</small>
    </div>
    <section className="event-list">
      <ul>
        <li>
          October 22, 2020:
          <a
            href="https://www.loyaltybookstores.com/church"
            target="_blank"
            rel="noopener noreferrer"
          >
            Deesha Philyaw and Adam Smyer for The Secret Lives of Church Ladies,
            Loyalty Bookstore (Petworth, DC)
          </a>
        </li>
        <li>
          October 17th, 2020:
          <a
            href="https://twincitiesbookfestival.com/personal-narrative-as-political-struggle/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Personal Narrative as Political Struggle: Pamela Sneed, Adam Smyer,
            and Frank Wilderson, III, Twin Cities Book Festival (Minneapolis/St.
            Paul, MN)
          </a>
        </li>
        <li>
          October 10, 2020:
          <a
            href="https://litquake2020festival.sched.com/event/dnwi/debut-authors-making-a-splash-during-the-apocalypse?iframe=no"
            target="_blank"
            rel="noopener noreferrer"
          >
            Panel moderator - Debut Authors: Making a Splash During the
            Apocalypse, Litquake Festival (San Francisco, CA)
          </a>
        </li>
        <li>
          October 7, 2020:
          <a
            href="https://www.youtube.com/watch?v=dw4QCKz-MAQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            In conversation with Lisa Deaderick, Warwick's Bookstore (La Jolla,
            CA)
          </a>
        </li>
        <li>
          October 6, 2020:
          <a
            href="https://colorsofinfluence.podbean.com/e/you-can-keep-that-to-yourself"
            target="_blank"
            rel="noopener noreferrer"
          >
            Colors of Influence Podcast
          </a>
        </li>
        <li>
          October 2, 2020:
          <a
            href="https://www.greenlightbookstore.com/event/bkindie2020"
            target="_blank"
            rel="noopener noreferrer"
          >
            Annual Brooklyn Indie Party, Greenlight Bookstore (Brooklyn, NY)
          </a>
        </li>
        <li>
          October 1, 2020:
          <a
            href="https://www.facebook.com/permalink.php?story_fbid=2425494127759895&id=1785299608446020"
            target="_blank"
            rel="noopener noreferrer"
          >
            In conversation with James Tracy, The Book House (Millburn, NJ)
          </a>
        </li>
        <li>
          September 22, 2020:
          <a
            href="https://www.youtube.com/watch?v=RzYfM1seGoU"
            target="_blank"
            rel="noopener noreferrer"
          >
            In conversation with Mat Johnson, Moe's Books (Berkeley, CA)
          </a>
        </li>
        <li>
          September 18, 2020:
          <a
            href="https://sexyliberal.com/adam-smyer-i-figured-out-how-to-say-buttigieg-and-people-still-cant-pronounce-kamala/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stephanie Miller's Happy Hour Podcast
          </a>
        </li>
        <li>
          September 17, 2020:
          <a
            href="https://www.youtube.com/watch?v=VTgSQNk3u-o"
            target="_blank"
            rel="noopener noreferrer"
          >
            In conversation with Deesha Philyaw, Belmont Books (Belmont, MA)
          </a>
        </li>
        <li>
          September 17, 2020:
          <a
            href="https://diymfa.com/reading/interview-adam-smyer"
            target="_blank"
            rel="noopener noreferrer"
          >
            DIY MFA
          </a>
        </li>
        <li>
          September 2, 2020:
          <a
            href="https://www.greenlightbookstore.com/podcastQS25"
            target="_blank"
            rel="noopener noreferrer"
          >
            In conversation with Irma Herrera, Greenlight Bookstore (Brooklyn,
            NY)
          </a>
        </li>
        <li>
          September 1, 2020:
          <a
            href="https://www.sfgrotto.org/episode-135-adam-smyers-anti-racist-translation-guide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            GrottoPod
          </a>
        </li>
        <li>
          September 1, 2020:
          <a
            href="https://askaleader.com/?p=2019"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ask A Leader
          </a>
        </li>
        <li>
          August 30, 2020:
          <a
            href="https://www.sfexaminer.com/entertainment/funny-guidebook-assists-people-of-pallor/"
            target="_blank"
            rel="noopener noreferrer"
          >
            San Francisco Examiner
          </a>
        </li>
        <li>
          August 28, 2020:
          <a
            href="https://wgntv.com/morning-news/adam-smyer-joins-wgn-morning-news-to-discuss-upcoming-book-you-can-keep-that-to-yourself/"
            target="_blank"
            rel="noopener noreferrer"
          >
            WGN Morning News
          </a>
        </li>
        <li>
          August 15, 2020:
          <a
            href="https://radiofreebrooklyn.com/show/art-star-scene-radio/?episode=RADIO3273941295"
            target="_blank"
            rel="noopener noreferrer"
          >
            Art Star Scene Radio 261
          </a>
        </li>
        <li>
          June 27, 2020:
          <a
            href="https://radiofreebrooklyn.com/show/art-star-scene-radio/?episode=RADIO1600365975"
            target="_blank"
            rel="noopener noreferrer"
          >
            Art Star Scene Radio 253
          </a>
        </li>
        <li>
          October 21, 2019:
          <a
            href="https://www.youtube.com/watch?v=Kl9faOU-h44"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Badass Bookworm
          </a>
        </li>
        <li>
          October 9, 2019:
          <a
            href="https://everythingwentblackmedia.com/everything-went-black-posts/2019/10/9/ewb-159-adam-smyer-author-of-knucklehead"
            target="_blank"
            rel="noopener noreferrer"
          >
            Everything Went Black 159
          </a>
        </li>
        <li>
          August 20, 2018:
          <a
            href="http://thecritter.co.za/?p=2956"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Critter
          </a>
        </li>
        <li>
          August 5, 2018:
          <a
            href="https://www.capetalk.co.za/podcasts/135/late-nights-with-sara-jayne-king/97987/book-club-knucklehead"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cape Talk
          </a>
        </li>
        <li>
          April 12, 2018
          <a
            href="https://radiofreebrooklyn.com/show/art-star-scene-radio/?episode=RADIO2178896631"
            target="_blank"
            rel="noopener noreferrer"
          >
            Art Star Scene Radio 139
          </a>
        </li>
        <li>
          March 3, 2018:
          <a
            href="https://48hills.org/2018/03/knucklehead-dam-smyer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            48 Hills
          </a>
        </li>
        <li>
          February 2, 2018:
          <a
            href="https://kazibookreview.files.wordpress.com/2018/05/adam-smyer-podcast-version.mp3"
            target="_blank"
            rel="noopener noreferrer"
          >
            KAZI Book Review
          </a>
        </li>
      </ul>
    </section>
  </Layout>
)

export default EventsPage
